import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import TinyPhoto from "../components/TinyPhoto/TinyPhoto";
import TinyClear from "../components/TinyPhoto/TinyClear";
import TinyReview from "../components/TinyReview/TinyReview";
import TinyReview2 from "../components/TinyReview2/TinyReview2";
import config from "../../data/SiteConfig";

class AboutPage extends Component {
  render() {
    return (
      <Layout>
        <div className="singlePage">
          <Helmet title={`♡ ${config.siteTitle}`} />
          <div>
          <TinyReview
              title="Joyland" author="Stephen King"
              cover="13596166.jpg"
          >
          Set in a small-town North Carolina amusement park in 1973, Joyland tells the story of the summer in which college student Devin Jones comes to work as a carny and confronts the legacy of a vicious murder, the fate of a dying child, and the ways both will change his life forever.
          </TinyReview>

          <p>Between texts.</p>

          <TinyReview
              title="The New Codependency: Help and Guidance for Today's Generation" author="Melody Beattie"
              cover="13596166.jpg"
              link="https://www.goodreads.com/book/show/13596166-joyland"
              linkText="Goodreads"
          >
          In "Codependent No More, " Melody Beattie introduced the world to the term "codependency." Now a modern classic, this book established Beattie as a pioneer in self-help literature and endeared her to millions of readers who longed for healthier relationships. Twenty-five years later concepts such as self-care and setting boundaries have become entrenched in mainstream culture. Now Beattie has written a followup volume, "The New Codependency, " which clears up misconceptions about codependency, identifies how codependent behavior has changed, and provides a new generation with a road map to wellness.
          </TinyReview>

          <p>Line between.</p>

          <TinyReview
              title="The Stand" author="Stephen King"
              cover="13596166.jpg"
              link="https://www.goodreads.com/book/show/13596166-joyland"
          >
          Set in a small-town North Carolina amusement park in 1973, Joyland tells the story of the summer in which college student Devin Jones comes to work as a carny and confronts the legacy of a vicious murder, the fate of a dying child, and the ways both will change his life forever. Set in a small-town North Carolina amusement park in 1973, Joyland tells the story of the summer in which college student Devin Jones comes to work as a carny and confronts the legacy of a vicious murder, the fate of a dying child, and the ways both will change his life forever.
          </TinyReview>

          <p>Line between.</p>

          <TinyReview2
              title="Attached" author="Amir Levine, Rachel S.F. Heller"
              cover="13596166.jpg"
              link="https://www.goodreads.com/book/show/13596166-joyland"
              rating="4"
              genre="Horror/Mystical"
              tw="Grief/loss"
              summary="According to attachment theory, every person behaves in relationships in one of three distinct ways: ANXIOUS, AVOIDANT, SECURE."
          >
          Picked up this book since I needed a nonfiction refresher to break up the horror. This sounded interesting and I hear good things about it, so I decided to give it a read!
          </TinyReview2>

          <p>Line between.</p>

          <TinyReview2
              title="How To Sell A Haunted House" author="Stephen King"
              cover="13596166.jpg"
              rating="none"
              genre="Horror/Mystical"
              summary="House of Leaves meets xxxxxx"
              opener="Your grave turned into a bookmark we couldn't get past. That we kept going back to."
          >
          This book piqued my curiosity when I was browsing through the top recommended horror books of 2021. Haunted devices are fairly relatable and continue the theme of haunted "houses" without being too "house-like" or gothic house themes.

          A fair number of reviews mentioned this was heart-wrenching, and... my gosh. I didn't expect the story to turn as it did a few times and I had to take a bit of a break after finishing it (reading it straight through for all my free and awakened moments over a few days) because it was so... glum. That said... I think this definitely was a page-turner and though I don't think I would consider it scary, it has a fair number of unsettling and eerie scenes. It does get a bit of cosmic horror. Overall, this has stuck around in my mind which is kind of rare since I have poor memory with books...
          </TinyReview2>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AboutPage;